import { Button } from "antd";
import classes from "./user.module.css";

export default function FinishExam({
    moduleId,
    modules,
    quizId,
    navigate,
    finishExam,
    setFinishExamModal,
    isNextModuleActive,
    setIsNextModuleActive,
    setCurrent,
    setEndTime,
    handleGoNext,
    isTimeExpire,
}) {
    return (
        <div className={classes.finish_exam}>
            {/* <p>Are you sure that you want to complete this module? If you complete this module, you will not be able to go back</p> */}
            <p>{isTimeExpire ? "Time is up" : "Do you want to finish this module?"}</p>
            <div>
                <Button type="primary" onClick={() => finishExam()} disabled={isNextModuleActive}>
                    Finish module
                </Button>
                {Number(moduleId) !== 4 && (
                    <Button disabled={!isNextModuleActive} type="primary" onClick={handleGoNext}>
                        Start next module
                    </Button>
                )}
                {!isTimeExpire && <Button onClick={() => setFinishExamModal(false)}>Cancel</Button>}
            </div>
        </div>
    );
}
