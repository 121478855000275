import { Button, Form, Input, message, Select, Typography } from "antd";
import axios from "axios";
import { base_url } from "config/config";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { loading, ready } from "redux/reducers/LoaderSlice";
import { store } from "redux/store";
import classes from "./login.module.css";
const { Title } = Typography;

const Login = () => {
    const [quizzes, setQuizzes] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        store.dispatch(loading());
        axios.get(base_url + "quizs", "get").then((res) => {
            setQuizzes(res.data);
            store.dispatch(ready());
        });

        const currentModule = localStorage.getItem("currentModule");
        const currentQuiz = localStorage.getItem("currentQuiz");
        const promoCode = localStorage.getItem("promoCode");

        // eger imtahan bitmeden logine qayidibsa imtahan sehifesine yonlendir
        if (currentModule && currentQuiz && promoCode) {
            navigate(`/exam?quizId=${currentQuiz}&moduleId=${currentModule}`);
        }
    }, []);

    // useEffect(async () => {
    // if (new Date(localStorage.getItem("endTime")) > new Date()) {
    //     navigate("/select-exam");
    // }
    // }, []);

    const onFinish = (values) => {
        store.dispatch(loading());
        localStorage.clear();
        let formData = {
            lastName: values.lastName,
            name: values.name,
            promoCode: values.promoCode,
        };

        axios
            .post(base_url + "examiners", formData)
            .then(async (res) => {
                let now = new Date();
                let moduleEndTime = null;
                const response = await axios.get(base_url + "modules", "get");
                response.data.map((item) => {
                    if (item.id == 1) {
                        moduleEndTime = new Date(now.getTime() + item.duration * 60000);
                    }
                });
                localStorage.setItem("endTime", res?.data?.endTime);
                localStorage.setItem("name", res?.data?.name);
                localStorage.setItem("lastName", res?.data?.lastName);
                localStorage.setItem("examinerId", res?.data?.id);
                localStorage.setItem("promoCode", values.promoCode);
                localStorage.setItem(`moduleEndTime1`, moduleEndTime);
                localStorage.setItem(`currentModule`, 1);
                localStorage.setItem(`currentQuiz`, values.quizId);
                store.dispatch(ready());
                navigate(`/exam?quizId=${values.quizId}&moduleId=1`);
            })
            .catch(() => {
                message.error("Wrong ExamID");
                store.dispatch(ready());
            });
    };

    return (
        <div className={classes.login}>
            <Form name="normal_login" className={classes.loginForm} onFinish={onFinish}>
                <Typography>
                    <Title type="primary">Login</Title>
                </Typography>

                <Form.Item
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Enter your name",
                        },
                    ]}
                >
                    <Input placeholder="Name" />
                </Form.Item>
                <Form.Item
                    name="lastName"
                    rules={[
                        {
                            required: true,
                            message: "Enter your surname",
                        },
                    ]}
                >
                    <Input placeholder="Surname" />
                </Form.Item>
                <Form.Item
                    name="promoCode"
                    rules={[
                        {
                            required: true,
                            message: "Enter your ExamID",
                        },
                    ]}
                >
                    <Input placeholder="ExamID" />
                </Form.Item>
                <Form.Item
                    name="quizId"
                    rules={[
                        {
                            required: true,
                            message: "Choose an exam",
                        },
                    ]}
                >
                    <Select placeholder="Choose an exam" style={{ textAlign: "left" }}>
                        {quizzes.map((item) => (
                            <Select.Option value={item.id} key={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Enter
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};
export default Login;
