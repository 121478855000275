import React from "react";
import expressions from "../../assets/images/expressions.webp";

export default function ExpressionsModal() {
    return (
        <div>
            <img style={{ width: "750px", height: "550px", border: "1px solid rgba(0, 0, 0, 0.2)" }}  alt="" src={expressions} />
        </div>
    );
}
