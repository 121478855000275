import { Button, Popconfirm, Table } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { httpClient } from "config/config";
import { useEffect, useState } from "react";
import TextModal from "./TextModal";
import parse from "html-react-parser";

export default function AddText() {
    const [isVisible, setIsVisible] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editData, setEditData] = useState();
    const [texts, setTexts] = useState([]);

    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [totalSize, setTotalSize] = useState();

    useEffect(() => {
        getTexts();
    }, []);

    const columns = [
        {
            title: "ID",
            key: "id",
            dataIndex: "id",
            width: 100,
            ellipsis: true,
        },
        {
            title: "Text",
            key: "textContent",
            dataIndex: "textContent",
            render: (value) => {
                return (
                    <>
                        {value.isText ? (
                            <div className="custom_quil_heading">{parse(value.textContent)}</div>
                        ) : (
                            <img style={{ maxWidth: "100%" }} src={value.textContent} />
                        )}
                    </>
                );
            },
            // width: "85%",
            // ellipsis:true
        },
        {
            title: "Əməliyyatlar",
            render: (values) => {
                return (
                    <div className="table_buttons">
                        <Button
                            onClick={() => {
                                setEditData(values);
                                setEditMode(true);
                                setIsVisible(true);
                            }}
                        >
                            <EditOutlined />
                        </Button>
                        <Popconfirm
                            title={`Id: ${values.id} silinsin?`}
                            onConfirm={() => deleteHandler(values?.key)}
                        >
                            <Button>
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    </div>
                );
            },
            width: 130,
            ellipsis: true,
        },
    ];

    const getTexts = (pageSize1, pageNo) =>
        httpClient(
            `textQuestions?pageNo=${pageNo ? pageNo - 1 : pageIndex}&pageSize=${
                pageSize1 ? pageSize1 : pageSize
            }&sortBy=id`
        ).then((res) => {
            setTexts(res.data.content);
            setTotalSize(res.data?.totalElements);
        });

    const deleteHandler = (id) => {
        httpClient("textQuestions/" + id, "delete").then(() => getTexts());
    };

    const tableData = texts.map((item) => {
        return {
            key: item.id,
            id: item.id,
            textContent: item,
            isText: item.isText,
        };
    });

    const changePage = (pg) => {
        setPageSize(pg.pageSize);
        setPageIndex(pg.current);
        getTexts(pg.pageSize, pg.current);
    };

    return (
        <div>
            <div className="module_header">
                <h3>Textlər</h3>
                <Button onClick={() => setIsVisible(true)}>Əlavə et</Button>
            </div>
            <Table
                pagination={{
                    defaultPageSize: pageSize,
                    showSizeChanger: true,
                    pageSizeOptions: ["5", "10", "20"],
                    total: totalSize,
                }}
                onChange={changePage}
                columns={columns}
                dataSource={tableData}
                // scroll={{x: "100%"}}
            />
            <TextModal
                getTexts={getTexts}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                editMode={editMode}
                setEditMode={setEditMode}
                editData={editData}
            />
        </div>
    );
}
