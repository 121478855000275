import React, { useEffect } from "react";
import Desmos from "desmos";

const elt = document.createElement("div");
elt.style.width = "600px";
elt.style.height = "400px";

const calculator = Desmos.GraphingCalculator(elt);
calculator.setExpression({ id: "graph1", latex: "y=x^2" });

export default function CalculatorModal() {
    useEffect(() => {
        document.getElementById("calculator")?.prepend(elt);
    }, []);
    return <div id="calculator"></div>;
}
