import Modules from "./DashboardTables/Modules/Modules";
import Quizzes from "./DashboardTables/Quizzes/Quizzes";
import Sections from "./DashboardTables/Sections/Sections";
import classes from "./adminDashboard.module.css";
import "./dashboard.css";

export default function AdminDashboard() {
    return (
        <div className={classes.dashboard}>
            <Sections />
            <Modules />
            <Quizzes />
        </div>
    );
}
