import React, { useEffect, useState } from "react";
import { Button, Form, message, Popconfirm, Select } from "antd";
import { base_url } from "config/config";
import { useNavigate } from "react-router";
import classes from "./user.module.css";
import axios from "axios";
import { useTimer } from "react-timer-hook";

export default function SelectExam() {
    const [sections, setSections] = useState([]);
    const [modules, setModules] = useState([]);
    const [quizzes, setQuizzes] = useState([]);
    const [selectedSection, setSelectedSection] = useState();
    const [endTime, setEndTime] = useState(localStorage.getItem("endTime"));
    const navigate = useNavigate();

    const { seconds, minutes, hours } = useTimer({
        expiryTimestamp: new Date(endTime),
        onExpire: () => navigate("/select-exam"),
    });

    useEffect(() => {
        if (new Date(localStorage.getItem("endTime")) <= new Date()) {
            navigate("/");
        }

        const getSections = axios.get(base_url + "sections", "get");
        const getQuiz = axios.get(base_url + "quizs", "get");
        Promise.all([getSections, getQuiz]).then((res) => {
            setSections(res[0].data);
            setQuizzes(res[1].data);
        });
    }, []);

    const onFinish = (value) => {
        let promoCode = localStorage.getItem("promoCode");
        const oldModuleEndTime = localStorage.getItem(`moduleEndTime${value.moduleId}`);

        if (
            oldModuleEndTime &&
            oldModuleEndTime.split("-")[0] == promoCode &&
            new Date(oldModuleEndTime) <= new Date()
        ) {
            message.error("Bu modulun vaxtı bitmişdir!");
            return;
        } else if (oldModuleEndTime && oldModuleEndTime.split("-")[0] == promoCode) {
            navigate(`/exam?quizId=${value.quizId}&moduleId=${value.moduleId}`);
        } else {
            let moduleEndTime = null;
            let now = new Date();
            modules.map((item) => {
                if (item.id == value.moduleId) {
                    moduleEndTime = item.duration;
                }
            });

            let data1 = new Date(endTime);
            let data2 = new Date(now.getTime() + moduleEndTime * 60000);

            if (new Date(data1) < new Date(data2)) {
                let storageData = `${promoCode}-${new Date(endTime)}`;
                localStorage.setItem(`moduleEndTime${value.moduleId}`, storageData);
                navigate(`/exam?quizId=${value.quizId}&moduleId=${value.moduleId}`);
            } else {
                let storageData = `${promoCode}-${new Date(now.getTime() + moduleEndTime * 60000)}`;
                localStorage.setItem(`moduleEndTime${value.moduleId}`, storageData);
                navigate(`/exam?quizId=${value.quizId}&moduleId=${value.moduleId}`);
            }
        }
    };
    const confirm = () => {
        localStorage.clear();
        navigate("/");
    };

    return (
        <div>
            <div>
                <p style={{ marginTop: "10px", fontSize: "18px" }}>
                    {hours < 10 && 0}
                    {hours}:{minutes < 10 && 0}
                    {minutes}:{seconds < 10 && 0}
                    {seconds}
                </p>
            </div>
            <div className={classes.select_box}>
                <Form layout="vertical" onFinish={onFinish} className={classes.select_box_form}>
                    <Popconfirm
                        okText="Ok"
                        onConfirm={confirm}
                        cancelText="Cancel"
                        title="Exit"
                        description="Are you sure to finish the test?"
                    >
                        <Button type="link" style={{ paddingBottom: "50px" }}>
                            Exit
                        </Button>
                    </Popconfirm>
                    <Form.Item
                        label="Choose a section"
                        name="sectionId"
                        rules={[
                            {
                                required: true,
                                message: "Choose a section",
                            },
                        ]}
                    >
                        <Select
                            onChange={(e) =>
                                axios.get(base_url + "sections/" + e).then((res) => {
                                    setModules(res?.data?.modules);
                                    setSelectedSection(e);
                                })
                            }
                        >
                            {sections.map((item) => (
                                <Select.Option value={item.id} key={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Choose a module"
                        name="moduleId"
                        rules={[
                            {
                                required: true,
                                message: "Choose a module",
                            },
                        ]}
                    >
                        <Select disabled={!selectedSection}>
                            {modules.map((item) => (
                                <Select.Option value={item.id} key={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Choose an exam"
                        name="quizId"
                        rules={[
                            {
                                required: true,
                                message: "Choose an exam",
                            },
                        ]}
                    >
                        <Select>
                            {quizzes.map((item) => (
                                <Select.Option value={item.id} key={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Button htmlType="submit">Start</Button>
                </Form>
            </div>
        </div>
    );
}
