import { Button, Form, Input, InputNumber, Modal, Select } from "antd";
import { httpClient } from "config/config";
import { useEffect, useState } from "react";

export default function ModulesModal({
    isVisible,
    setIsVisible,
    getSections,
    editData,
    editMode,
    setEditMode,
}) {
    const [sections, setSections] = useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
        httpClient("sections").then((res) => setSections(res?.data));
    }, []);

    useEffect(() => {
        if (editMode) {
            form.setFieldsValue({
                ["name"]: editData?.name,
                ["sectionId"]: editData?.sectionId,
                ["numberOfQuestion"]: editData?.numberOfQuestion,
                ["duration"]: editData?.duration,
            });
        }
    }, [editMode]);

    const onCancel = () => {
        form.resetFields();
        setIsVisible(false);
        setEditMode(false);
    };

    const onFinish = (value) => {
        const data = {
            name: value.name,
            sectionId: value.sectionId,
            numberOfQuestion: value.numberOfQuestion,
            duration: value.duration,
        };

        const requestType = editMode ? "put" : "post";
        const endpoint = editMode ? `modules/${editData.key}` : "modules";

        httpClient(endpoint, requestType, data).then(() => {
            onCancel();
            getSections();
        });
    };
    return (
        <Modal onCancel={onCancel} footer={null} open={isVisible} title="Modul əlavə et">
            <Form
                onFinish={onFinish}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                labelAlign="left"
                form={form}
            >
                <Form.Item
                    name="name"
                    label="Modul adı"
                    rules={[
                        {
                            required: true,
                            message: "Modul adını seçin",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="sectionId"
                    label="Section"
                    rules={[
                        {
                            required: true,
                            message: "Section seçin",
                        },
                    ]}
                >
                    <Select>
                        {sections.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="numberOfQuestion"
                    label="Sual sayı"
                    rules={[
                        {
                            required: true,
                            message: "Sual sayını daxil edin",
                        },
                    ]}
                >
                    <InputNumber type="number" min={0} />
                </Form.Item>
                <Form.Item
                    name="duration"
                    label="Müddət"
                    rules={[
                        {
                            required: true,
                            message: "Müddəti daxil edin",
                        },
                    ]}
                >
                    <InputNumber type="number" min={0} placeholder="(dəqiqə ilə)" />
                </Form.Item>
                <Button htmlType="submit">Əlavə et</Button>
            </Form>
        </Modal>
    );
}
