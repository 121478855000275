import { useEffect, useState } from "react";
import { Button, Popconfirm, Table } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { httpClient } from "config/config";
import ModulesModal from "./ModulesModal";

export default function Modules() {
    const [isVisible, setIsVisible] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editData, setEditData] = useState();
    const [modules, setModules] = useState([]);

    const getModules = () => {
        httpClient("modules", "get").then((res) => setModules(res.data));
    };

    useEffect(() => {
        getModules();
    }, []);

    const deleteHandler = (id) => {
        httpClient("modules/" + id, "delete").then(() => getModules());
    };

    const column = [
        {
            title: "Adı",
            dataIndex: "name",
            width: "20%",
        },
        {
            title: "Section",
            dataIndex: "section",
            width: "25%",
        },
        {
            title: "Sual sayı",
            dataIndex: "numberOfQuestion",
            width: "75",
        },
        {
            title: "Müddət",
            dataIndex: "duration",
            width: "75",
        },
    ];

    const tableData = modules.map((item) => {
        return {
            name: item.name,
            section: item?.section?.name,
            sectionId: item?.section?.id,
            numberOfQuestion: item?.numberOfQuestion,
            duration: item?.duration,
            key: item.id,
        };
    });

    return (
        <div>
            <div className="module_header">
                <h3>Modullar</h3>
                <Button onClick={() => setIsVisible(true)}>Əlavə et</Button>
            </div>
            <Table
                pagination={false}
                dataSource={tableData}
                columns={column}
                scroll={{ y: "300px" }}
            />
            <ModulesModal
                editData={editData}
                editMode={editMode}
                getSections={getModules}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                setEditMode={setEditMode}
            />
        </div>
    );
}
