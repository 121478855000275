import { useEffect } from "react";
import { Button, Form, Input, Modal } from "antd";
import { httpClient } from "config/config";

export default function SectionsModal({
    isVisible,
    setIsVisible,
    getSections,
    editData,
    editMode,
    setEditMode,
}) {
    const [form] = Form.useForm();

    const onCancel = () => {
        form.resetFields();
        setIsVisible(false);
        setEditMode(false);
    };

    useEffect(() => {
        if (editMode) {
            form.setFieldsValue({
                ["name"]: editData?.name,
            });
        }
    }, [editMode]);

    const onFinish = (value) => {
        const data = { name: value.name, status: true };
        if (editMode) {
            httpClient("sections/" + editData.key, "put", data).then(() => {
                onCancel();
                getSections();
            });
        } else {
            httpClient("sections", "post", data).then(() => {
                onCancel();
                getSections();
            });
        }
    };

    return (
        <Modal
            onCancel={onCancel}
            footer={null}
            open={isVisible}
            title={editMode ? "Section adına düzəliş et" : "Section əlavə et"}
        >
            <Form form={form} onFinish={onFinish} className="add_form">
                <Form.Item
                    name="name"
                    label="Section adı"
                    rules={[
                        {
                            required: true,
                            message: "Section adını daxil edin",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Button htmlType="submit">{editMode ? "Yadda saxla" : "Əlavə et"}</Button>
            </Form>
        </Modal>
    );
}
