import React from "react";
import { Modal } from "antd";
import classes from "./checkAnswers.module.css";

export default function ShowResult({ isVisible, setIsVisible, data }) {
    const onCancel = () => {
        setIsVisible(false);
    };

    return (
        <Modal
            title="İmtahan nəticəsi"
            open={isVisible}
            footer={null}
            style={{ height: "200px" }}
            onCancel={onCancel}
            destroyOnClose={true}
        >
            <div className={classes.show_result}>
                <div className={classes.student}>
                    Tələbə:{" "}
                    <span>
                        {data?.examiner?.name} {data?.examiner?.lastName}
                    </span>
                </div>

                {data?.usersAnswersCountReportResponseDtos?.map((item) => (
                    <div className={classes.result_main}>
                        <div className={classes.result_header}>
                            <div>{item?.module?.section?.name}</div>
                            &gt;
                            <div>{item?.module?.name}</div>
                        </div>
                        <div className={classes.result_number}>
                            <div>
                                Sual sayı: <span>{item?.module?.numberOfQuestion}</span>
                            </div>
                            <div>
                                Düzgün cavab sayı: <span>{item?.score}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Modal>
    );
}
