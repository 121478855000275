// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".questionForm_upload_item__HM-Fs{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: flex-start;\r\n    gap: 30px;\r\n    margin-bottom: 10px;\r\n    width: 100%;\r\n}\r\n.questionForm_answerText_Radio__9OvUB{\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 24px;\r\n    margin-top: 30px;\r\n}", "",{"version":3,"sources":["webpack://./src/pages/admin/AddQuestion/QuestionForm/questionForm.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,SAAS;IACT,mBAAmB;IACnB,WAAW;AACf;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,gBAAgB;AACpB","sourcesContent":[".upload_item{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: flex-start;\r\n    gap: 30px;\r\n    margin-bottom: 10px;\r\n    width: 100%;\r\n}\r\n.answerText_Radio{\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 24px;\r\n    margin-top: 30px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upload_item": "questionForm_upload_item__HM-Fs",
	"answerText_Radio": "questionForm_answerText_Radio__9OvUB"
};
export default ___CSS_LOADER_EXPORT___;
