import { useEffect, useState } from "react";
import { Button, Table, Popconfirm } from "antd";
import QuizzesModal from "./QuizzesModal";
import { httpClient } from "config/config";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

export default function Quizzes() {
    const [isVisible, setIsVisible] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editData, setEditData] = useState();
    const [quizzes, setQuizzes] = useState([]);

    const getQuizzes = () => {
        httpClient("quizs", "get").then((res) => setQuizzes(res.data));
    };

    useEffect(() => {
        getQuizzes();
    }, []);

    const deleteHandler = (id) => {
        httpClient("quizs/" + id, "delete").then(() => getQuizzes());
    };

    const column = [
        {
            title: "Adı",
            dataIndex: "name",
            width: "80%",
        },
        {
            title: "Əməliyyatlar",
            render: (values) => {
                return (
                    <div className="table_buttons">
                        <Button
                            onClick={() => {
                                setEditData(values);
                                setEditMode(true);
                                setIsVisible(true);
                            }}
                        >
                            <EditOutlined />
                        </Button>
                        <Popconfirm
                            title={`${values.name} silinsin?`}
                            onConfirm={() => deleteHandler(values?.key)}
                        >
                            <Button>
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];

    const tableData = quizzes.map((item) => {
        return {
            name: item.name,
            key: item.id,
        };
    });

    return (
        <div>
            <div className="module_header">
                <h3>Quizlər</h3>
                <Button onClick={() => setIsVisible(true)}>Əlavə et</Button>
            </div>
            <Table
                pagination={false}
                scroll={{ y: "300px" }}
                columns={column}
                dataSource={tableData}
            />
            <QuizzesModal
                editData={editData}
                editMode={editMode}
                getQuizzes={getQuizzes}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                setEditMode={setEditMode}
            />
        </div>
    );
}
