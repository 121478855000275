import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { message, Radio } from "antd";
import Progress from "components/Progress/Progress";
import { httpClient } from "config/config";
import classes from "./checkAnswers.module.css";
import parse from "html-react-parser";

export default function CheckAnswers() {
    const [current, setCurrent] = useState(1);
    const [question, setQuestion] = useState({});
    let [searchParams] = useSearchParams();
    const moduleId = searchParams.get("moduleId");
    const quizId = searchParams.get("quizId");
    const promoCode = searchParams.get("promoCode");
    const [numberOfQuestion, setNumberOfQuestion] = useState();
    const [moduleName, setModulName] = useState();
    const [sectionName, setSectionName] = useState();
    const [trueAnswer, setTrueAnswer] = useState();
    const [userAnswerId, setUserAnswerId] = useState();
    const [userOpenAnswer, setUserOpenAnswer] = useState();
    const [name, setName] = useState();
    const [lastName, setLastName] = useState();
    const [userAnswers, setUserAnswers] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        setTrueAnswer();
        httpClient(`report/userAnswer/${quizId}/${moduleId}/${current}/${promoCode}`)
            .then((res) => {
                setQuestion(res?.data);
                setNumberOfQuestion(res?.data?.module.numberOfQuestion);
                setSectionName(res?.data?.module?.section?.name);
                setModulName(res?.data?.module?.name);
                setUserAnswerId(res?.data?.userAnswerId);
                setUserOpenAnswer(res?.data?.userOpenAnswer);
                setName(res?.data?.name);
                setLastName(res?.data?.lastName);
                res.data?.answers?.map((item) => {
                    if (item.isTrue) {
                        setTrueAnswer(item.variant.id);
                    }
                });
            })
            .catch(() => {
                message.error("Yanlış promokod!");
                navigate("/admin/report");
            });
    }, [current]);

    useEffect(() => {
        httpClient(`report/userAnswerList/${quizId}/${moduleId}/${promoCode}`).then((res) =>
            setUserAnswers(res.data)
        );
    }, []);

    return (
        <div className={classes.begin}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                Tələbə:
                <h2>
                    {name} {lastName}
                </h2>
            </div>
            <h3>
                {sectionName} &gt; {moduleName}
            </h3>

            {question.textQuestion && (
                <div className={classes.text_question}>
                    <h5>Text sualı:</h5>

                    {question.textQuestion?.isText ? (
                        <div style={{ textAlign: "left" }}>
                            {parse(question.textQuestion?.textContent)}
                        </div>
                    ) : (
                        <img alt="" src={question.textQuestion?.textContent} />
                    )}
                </div>
            )}
            <h5>Sual:</h5>
            {<img alt="" src={question?.questionText} />}

            {question?.answers?.length > 0 && (
                <>
                    <h5>Cavablar:</h5>
                    <h4 style={{ color: "red" }}>{!name && "Tələbə bu suala cavab verməyib"}</h4>
                    {question?.isOpen ? (
                        <div className={classes.openAnswers}>
                            <div>
                                <span>Düzgün cavab: </span>
                                <span>
                                    {question.answers[0]?.answerText}
                                    {question.answers[1]?.answerText &&
                                        ` və ${question.answers[1]?.answerText}`}
                                </span>
                            </div>
                            <div>
                                <span>Tələbənin cavabı: </span> <span>{userOpenAnswer}</span>
                            </div>
                        </div>
                    ) : (
                        <Radio.Group
                            className={classes.radioGroup}
                            value={trueAnswer}
                            defaultValue={trueAnswer}
                        >
                            {question?.answers?.map((item) => {
                                return (
                                    <Radio
                                        disabled
                                        value={item?.variant?.id}
                                        key={item?.variant?.id}
                                        className={`${
                                            item?.variant?.id == trueAnswer
                                                ? "true_answer"
                                                : userAnswerId == item?.variant?.id &&
                                                  item?.variant?.id !== trueAnswer
                                                ? "false_answer"
                                                : "selected_answer"
                                        } custom_radio`}
                                    >
                                        <div className={classes.exam_radio}>
                                            <span className="variant">
                                                {item?.variant?.id == 1
                                                    ? "A"
                                                    : item?.variant?.id == 2
                                                    ? "B"
                                                    : item?.variant?.id == 3
                                                    ? "C"
                                                    : "D"}
                                            </span>
                                            {question.isAnswerText ? (
                                                <span
                                                    className={classes.rightImg}
                                                    style={{ color: "rgba(0,0,0,0.7)" }}
                                                >
                                                    {item?.answerText}
                                                </span>
                                            ) : (
                                                <img
                                                    src={item?.answerText}
                                                    className={classes.rightImg}
                                                />
                                            )}
                                        </div>
                                    </Radio>
                                );
                            })}
                        </Radio.Group>
                    )}
                </>
            )}

            <Progress
                data={numberOfQuestion}
                current={current}
                onChange={(e) => {
                    setCurrent(e);
                }}
                type="check_answers"
                userAnswerList={userAnswers}
            />
        </div>
    );
}
