import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Button, Input, message,  Popover, Radio, Spin } from "antd";
import { useTimer } from "react-timer-hook";
import { MinusCircleFilled, CalculatorOutlined, LineChartOutlined } from "@ant-design/icons";
import Progress from "components/Progress/Progress";
import { base_url } from "config/config";
import classes from "./user.module.css";
import CalculatorModal from "./CalculatorModal";
import ExpressionsModal from "./ExpressionsModal";
import axios from "axios";
import FinishExam from "./FinishExam";
import { loading, ready } from "redux/reducers/LoaderSlice";
import { store } from "redux/store";
import parse from "html-react-parser";

function Exam() {
    const [current, setCurrent] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [question, setQuestion] = useState();
    let [searchParams] = useSearchParams();
    const moduleId = searchParams.get("moduleId");
    const quizId = searchParams.get("quizId");
    const [numberOfQuestion, setNumberOfQuestion] = useState();
    const [moduleName, setModulName] = useState();
    const [sectionName, setSectionName] = useState();
    const [render, setRender] = useState(false);
    const [savedQuestions, setSavedQuestions] = useState([]);

    // modulun bitmə vaxtıdır
    const [endTime, setEndTime] = useState(new Date(localStorage.getItem(`moduleEndTime${moduleId}`)));
    
    const [isCalculatorVisible, setIsCalculatorVisible] = useState(false);
    const [isExpressionVisible, setIsExpressionVisible] = useState(false);

    const [trueAnswer, setTrueAnswer] = useState();
    const [answeredQuestions, setAnsweredQuestions] = useState({});
    const [removedAnswers, setRemovedAnswers] = useState({});
    const [isNextModuleActive, setIsNextModuleActive] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const [openAnswer, setOpenAnswer] = useState();

    const [finishExamModal, setFinishExamModal] = useState(false);
    const [showAnswerRemove, setShowAnswerRemove] = useState(false);
    const [isTimeExpire, setIsTimeExpire] = useState(false);

    const timerDate = new Date("2023-02-12T04:02:44.024+00:00");
    const { seconds, minutes, hours, pause } = useTimer({
        expiryTimestamp: new Date(endTime),
        onExpire: () => finishExam("goNext"),
    });
    const [modules, setModules] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setIsTimeExpire(false);
        if (localStorage.getItem("promoCode") && new Date(localStorage.getItem("endTime")) > new Date()) {
            if (moduleId != localStorage.getItem("currentModule") || quizId != localStorage.getItem("currentQuiz")) {
                // if(){

                window.location.replace(`/exam?quizId=${localStorage.getItem("currentQuiz")}&moduleId=${localStorage.getItem("currentModule")}`);
                // }else{

                // }
            }

            axios(base_url + "modules").then((res) => setModules(res.data));
            // let moduleTime = new Date(localStorage.getItem(`moduleEndTime${moduleId}`).split("-")[1]);
            // setEndTime(moduleTime);
            // if (moduleTime <= new Date()) {
            //     message.error("Bu modulun vaxtı bitib!")
            //     navigate("/select-exam");
            // }

            // axios
            //     .get(
            //         base_url + "promoCodes/check/" + localStorage.getItem("promoCode") + "/" + moduleId
            //     )
            //     .catch((err) => navigate("/select-exam"));

            axios(base_url + "modules/" + moduleId).then((res) => {
                setNumberOfQuestion(res?.data?.numberOfQuestion);
                setSectionName(res?.data?.section?.name);
                setModulName(res?.data?.name);
            });

            setAnsweredQuestions(
                localStorage.getItem(`answeredQuestions${moduleId}`) ? JSON.parse(localStorage.getItem(`answeredQuestions${moduleId}`)) : {}
            );
            setSavedQuestions(localStorage.getItem(`savedQuestions${moduleId}`) ? JSON.parse(localStorage.getItem(`savedQuestions${moduleId}`)) : []);

            setRemovedAnswers(localStorage.getItem(`removedAnswers${moduleId}`) ? JSON.parse(localStorage.getItem(`removedAnswers${moduleId}`)) : {});
            let oldState = removedAnswers;
            if (JSON.parse(localStorage.getItem(`removedAnswers${moduleId}`)) == undefined) {
                oldState[current] = [];
                setRemovedAnswers(oldState);
            }
        } else {
            let finishedExams = JSON.parse(localStorage.getItem("finishedExams"));
            if(!finishedExams?.includes(localStorage.getItem("currentModule"))){
                finishExam()
            }
            localStorage.clear();
            navigate("/");
        }
    }, []);

    const [annotationValue, setAnnotationValue] = useState(localStorage.getItem("annotation") ? JSON.parse(localStorage.getItem("annotation")) : {});

    useEffect(() => {
        setIsLoading(true);
        const answeredQuestions = JSON.parse(localStorage.getItem(`answeredQuestions${moduleId}`)) || {};
        const currentQuestion = answeredQuestions[current] || {};
        setOpenAnswer(currentQuestion.isOpen ? currentQuestion.value : undefined);

        const handleQuestionResponse = (res) => {
            setQuestion(res.data);
            setIsOpen(res.data.isOpen);

            const annotation = JSON.parse(localStorage.getItem("annotation")) || {};
            const questionId = res.data?.textQuestion?.id;

            if (!annotation[questionId]) {
                annotation[questionId] = [];
            }

            setAnnotationValue(annotation);
        };

        axios
            .get(`${base_url}questions/questionForExam/${quizId}/${moduleId}/${current}`)
            .then((res) => {
                handleQuestionResponse(res);
                setIsLoading(false);
            })
            .catch(() => {
                message.error("Sual mövcud deyil");
                setIsLoading(false);
            });

        setRender(!render);
    }, [current]);

    const handleAnswerChange = (e) => {
        setTrueAnswer(e.target.value);

        let oldState = answeredQuestions;
        oldState[current] = { isOpen: false, value: e.target.value };
        setAnsweredQuestions(oldState);
        localStorage.setItem(`answeredQuestions${moduleId}`, JSON.stringify(oldState));
    };

    const handleGoNext = () => {
        let finishedExams = JSON.parse(localStorage.getItem("finishedExams"));

        if (Number(moduleId) == 1) {
            let newExam = [1];
            localStorage.setItem("finishedExams", JSON.stringify(newExam));
        } else {
            finishedExams.push(Number(moduleId));
            localStorage.setItem("finishedExams", JSON.stringify(finishedExams));
        }

        let moduleEndTime = null;
        let now = new Date();
        modules.map((item) => {
            if (item.id == Number(moduleId) + 1) {
                moduleEndTime = new Date(now.getTime() + item.duration * 60000);
            }
        });
        localStorage.setItem(`moduleEndTime${Number(moduleId) + 1}`, moduleEndTime);

        // let data1 = new Date(endTime);
        // let data2 = new Date(now.getTime() + moduleEndTime * 60000);

        setIsNextModuleActive(false);
        setCurrent(1);
        setFinishExamModal(false);
        setEndTime(new Date(moduleEndTime));
        localStorage.setItem(`currentModule`, Number(moduleId) + 1);
        window.location.replace(`/exam?quizId=${quizId}&moduleId=${Number(moduleId) + 1}`);
    };

    const handleOpenAnswer = (value) => {
        setOpenAnswer(value);
        let oldState = answeredQuestions;
        oldState[current] = { isOpen: true, value };
        setAnsweredQuestions(oldState);
        localStorage.setItem(`answeredQuestions${moduleId}`, JSON.stringify(oldState));
    };

    const handleNext = () => {
        setCurrent(current + 1);
        setTrueAnswer();
    };
    const handleBack = () => {
        setCurrent(current - 1);
        setTrueAnswer();
    };

    const finishExam = (goNext) => {
        store.dispatch(loading());
        let openQuestionAnswer = {};
        let questionAnswer = {};
        Object.keys(answeredQuestions).map((item) => {
            if (answeredQuestions[item]?.isOpen) {
                openQuestionAnswer[item] = answeredQuestions[item]?.value;
            } else {
                questionAnswer[item] = answeredQuestions[item]?.value;
            }
        });

        let examData = {
            examinerId: Number(localStorage.getItem("examinerId")),
            moduleId: Number(localStorage.getItem("currentModule")),
            openQuestionAnswer,
            questionAnswer,
            quizId: Number(localStorage.getItem("currentQuiz")),
        };
        axios.post(base_url + "results", examData).then(() => {
            localStorage.removeItem(`answeredQuestions${moduleId}`);
            localStorage.removeItem(`removedAnswers${moduleId}`);
            localStorage.removeItem(`savedQuestions${moduleId}`);
            localStorage.removeItem(`moduleEndTime${moduleId}`);
            localStorage.removeItem("annotation");
            store.dispatch(ready());
            if (Number(moduleId) == 4) {
                localStorage.clear();
                navigate("/");
            } else {
                pause();
                setIsNextModuleActive(true);
            }
            if (goNext) {
                setIsTimeExpire(true);
                setFinishExamModal(true);
                // handleGoNext();
            }
        });
    };

    const handleRemoveAnswers = (variant) => {
        let oldState = removedAnswers;
        if (typeof oldState[current] == "object") {
            if (oldState[current].includes(variant)) {
                return;
            } else {
                let oldArray = oldState[current];
                oldArray.push(variant);
                oldState[current] = oldArray;
            }
        } else {
            oldState[current] = [];
            let oldArray = oldState[current];
            oldArray.push(variant);
            oldState[current] = oldArray;
        }
        setRemovedAnswers(oldState);
        localStorage.setItem(`removedAnswers${moduleId}`, JSON.stringify(oldState));
        setRender(!render);
    };

    const undeRemoveAnswers = (variant) => {
        let oldRemoved = removedAnswers;
        let oldState = removedAnswers[current];
        let newState = oldState.filter((item) => item != variant);
        oldRemoved[current] = newState;
        setRemovedAnswers(oldRemoved);
        localStorage.setItem(`removedAnswers${moduleId}`, JSON.stringify(oldRemoved));
        setRender(!render);
    };

    const handleSaveQuestions = () => {
        if (!savedQuestions.includes(current)) {
            let oldState = savedQuestions;
            oldState.push(current);
            setSavedQuestions(oldState);
            localStorage.setItem(`savedQuestions${moduleId}`, JSON.stringify(oldState));
            setRender(!render);
        } else {
            let oldState = savedQuestions;
            let newState = oldState.filter((item) => item != current);
            setSavedQuestions(newState);
            localStorage.setItem(`savedQuestions${moduleId}`, JSON.stringify(newState));
            setRender(!render);
        }
    };

    const content = (
        <div className={classes.popover}>
            <div className={classes.popover_position}>
                <div className={classes.popover_item}>
                    <svg className={classes.popover_icon} xmlns="" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
                        <path d="M 37 48 C 36.824219 48 36.652344 47.953125 36.496094 47.863281 L 25 41.15625 L 13.503906 47.863281 C 13.195313 48.042969 12.8125 48.046875 12.503906 47.867188 C 12.191406 47.6875 12 47.359375 12 47 L 12 3 C 12 2.449219 12.449219 2 13 2 L 37 2 C 37.554688 2 38 2.449219 38 3 L 38 47 C 38 47.359375 37.808594 47.6875 37.496094 47.867188 C 37.34375 47.957031 37.171875 48 37 48 Z"></path>
                    </svg>
                    <span>For review</span>
                </div>
                <div className={classes.popover_item}>
                    <div className={classes.popover_item_icon2}></div>
                    <span>Answered</span>
                </div>
                <div className={classes.popover_item}>
                    <div className={classes.popover_item_icon}></div>
                    <span>Current</span>
                </div>
            </div>
            <Progress
                data={numberOfQuestion}
                current={current}
                onChange={(e) => {
                    setTrueAnswer();
                    setCurrent(e);
                }}
                marked_questions={savedQuestions}
                orders={answeredQuestions && Object.keys(answeredQuestions).map((i) => Number(i))} // orders  = array of numbers
            />
        </div>
    );
    const handleAnnotationChange = (value, quesId) => {
        const newData = { ...annotationValue, [quesId]: value };

        setAnnotationValue(newData);
        localStorage.setItem("annotation", JSON.stringify(newData));
    };
    return (
        <Spin spinning={isLoading}>
            <div className={classes.common}>
                <div className={classes.top}>
                    <div className={`${classes.top_items} ${classes.exam_name}`}>
                        {sectionName}: {moduleName}
                    </div>
                    <div className={classes.top_items}>
                        <h4>
                            {hours < 10 && 0}
                            {hours}:{minutes < 10 && 0}
                            {minutes}:{seconds < 10 && 0}
                            {seconds}
                        </h4>
                    </div>
                    <div className={`${classes.calculator_settings} ${classes.top_items}`}>
                        {/* <Button onClick={() => setFinishExamModal(true)}>Modulu bitir</Button>
                        <Button>
                            <Link to="/select-exam">Modul seç</Link>
                        </Button> */}
                        <div
                            onClick={() => {
                                setIsExpressionVisible(false);
                                setIsCalculatorVisible(true);
                            }}
                            className={classes.settings_div}
                        >
                            <CalculatorOutlined />
                            <span>Calculator</span>
                        </div>
                        <div
                            onClick={() => {
                                setIsCalculatorVisible(false);
                                setIsExpressionVisible(true);
                            }}
                            className={classes.settings_div}
                        >
                            <LineChartOutlined />
                            <span>Reference</span>
                        </div>
                    </div>
                </div>

                <div className={classes.cont}>
                    <div className={classes.left}>
                        <div className={classes.leftImg}>
                            {question?.textQuestion?.isText ? (
                                <div style={{ textAlign: "left" }} className="custom_quil_heading">
                                    {question?.textQuestion?.isText ? parse(question?.textQuestion?.textContent) : parse(question?.questionText)}

                                    {/* <TextAnnotateBlend
                                        content={ */}
                                    {/* }
                                        onChange={(e) =>
                                            handleAnnotationChange(e, question?.textQuestion?.id)
                                        }
                                        value={annotationValue[question?.textQuestion?.id]}
                                        getSpan={(span) => ({
                                            ...span,
                                        })}
                                    /> */}
                                </div>
                            ) : (
                                <div className={classes.text_question_image}>
                                    {! question?.textQuestion && question?.isText ? (
                                        <div style={{ textAlign: "left" }} className="custom_quil_heading">{parse(question?.questionText)}</div>
                                    ) : (
                                        <img
                                            src={question?.textQuestion?.textContent ? question?.textQuestion?.textContent : question?.questionText}
                                            alt=""
                                            className={classes.leftImg}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={classes.right}>
                        <div className={classes.question_settings}>
                            <div className={classes.mark_question}>
                                <span className={classes.current_number}>{current}</span>
                                <div
                                    className={`${savedQuestions.includes(current) ? classes.saved_mark : classes.mark}`}
                                    onClick={() => handleSaveQuestions()}
                                >
                                    {/* <BookFilled  /> */}
                                    {savedQuestions.includes(current) ? (
                                        <svg className={classes.icon} xmlns="" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
                                            <path d="M 37 48 C 36.824219 48 36.652344 47.953125 36.496094 47.863281 L 25 41.15625 L 13.503906 47.863281 C 13.195313 48.042969 12.8125 48.046875 12.503906 47.867188 C 12.191406 47.6875 12 47.359375 12 47 L 12 3 C 12 2.449219 12.449219 2 13 2 L 37 2 C 37.554688 2 38 2.449219 38 3 L 38 47 C 38 47.359375 37.808594 47.6875 37.496094 47.867188 C 37.34375 47.957031 37.171875 48 37 48 Z"></path>
                                        </svg>
                                    ) : (
                                        <svg className={classes.icon} xmlns="" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
                                            <path d="M 12.8125 2 C 12.335938 2.089844 11.992188 2.511719 12 3 L 12 47 C 11.996094 47.359375 12.1875 47.691406 12.496094 47.871094 C 12.804688 48.054688 13.1875 48.054688 13.5 47.875 L 25 41.15625 L 36.5 47.875 C 36.8125 48.054688 37.195313 48.054688 37.503906 47.871094 C 37.8125 47.691406 38.003906 47.359375 38 47 L 38 3 C 38 2.449219 37.550781 2 37 2 L 13 2 C 12.96875 2 12.9375 2 12.90625 2 C 12.875 2 12.84375 2 12.8125 2 Z M 14 4 L 36 4 L 36 45.25 L 25.5 39.125 C 25.191406 38.945313 24.808594 38.945313 24.5 39.125 L 14 45.25 Z"></path>
                                        </svg>
                                    )}

                                    <span>For review</span>
                                </div>
                            </div>
                            {!isOpen && (
                                <MinusCircleFilled onClick={() => setShowAnswerRemove(!showAnswerRemove)} className={classes.icon_selected} />
                            )}
                        </div>
                        {question?.textQuestion?.textContent && (
                            <>
                                {question?.isText ? (
                                    <div style={{ textAlign: "left" }} className={`${classes.text_question_image} custom_quil_heading`}>{parse(question?.questionText)}</div>
                                ) : (
                                    <div className={classes.text_question_image}>
                                        <img alt="" src={question?.questionText} className={classes.has_text_question} />
                                    </div>
                                )}
                            </>
                        )}
                        {isOpen ? (
                            <div className={classes.openAns}>
                                <div>
                                    <h4>Cavab:</h4>
                                    <Input
                                        defaultValue={openAnswer}
                                        value={openAnswer}
                                        onChange={(e) => handleOpenAnswer(e.target.value)}
                                        // type="number"
                                        // placeholder="Cavab"
                                    />
                                </div>
                                <div className={classes.preview}>
                                    <span>Baxış:</span>
                                    <span>{openAnswer}</span>
                                </div>
                            </div>
                        ) : (
                            <Radio.Group
                                className={classes.radioGroup}
                                defaultValue={answeredQuestions[current]?.value}
                                value={trueAnswer ? trueAnswer : answeredQuestions[current]?.value}
                                onChange={(e) => {
                                    handleAnswerChange(e);
                                }}
                            >
                                {question?.answers?.map((item) => {
                                    return (
                                        <div className={classes.answers_list} key={item?.id}>
                                            <Radio
                                                danger
                                                className={`${
                                                    trueAnswer == item?.variant?.id || answeredQuestions[current]?.value == item?.variant?.id
                                                        ? classes.selected_radio
                                                        : classes.radio
                                                } custom_radio`}
                                                value={item?.variant?.id}
                                                disabled={removedAnswers[current]?.includes(item?.variant?.id)}
                                            >
                                                <div className={classes.exam_radio}>
                                                    <span className="variant">
                                                        {item?.variant?.id == 1
                                                            ? "A"
                                                            : item?.variant?.id == 2
                                                            ? "B"
                                                            : item?.variant?.id == 3
                                                            ? "C"
                                                            : "D"}
                                                    </span>
                                                    {question.isAnswerText ? (
                                                        <span
                                                            className={classes.rightImg}
                                                            style={{
                                                                opacity: `${removedAnswers[current]?.includes(item?.variant?.id) ? 0.4 : 1}`,
                                                                fontSize: "16px",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            {item?.answerText}
                                                        </span>
                                                    ) : (
                                                        <img
                                                            style={{
                                                                opacity: `${removedAnswers[current]?.includes(item?.variant?.id) ? 0.4 : 1}`,
                                                            }}
                                                            src={item?.answerText}
                                                            className={classes.rightImg}
                                                        />
                                                    )}
                                                </div>
                                                {removedAnswers[current]?.includes(item?.variant?.id) && (
                                                    <div className={classes.question_remove_border}></div>
                                                )}
                                            </Radio>
                                            {showAnswerRemove && (
                                                <div>
                                                    {removedAnswers[current]?.includes(item?.variant?.id) ? (
                                                        <p
                                                            className={classes.remove_variant_text}
                                                            onClick={() => undeRemoveAnswers(item?.variant?.id)}
                                                        >
                                                            Undo
                                                        </p>
                                                    ) : (
                                                        <MinusCircleFilled
                                                            onClick={() => handleRemoveAnswers(item?.variant?.id)}
                                                            className={classes.remove_variant_icon}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </Radio.Group>
                        )}
                    </div>
                </div>
                <div className={classes.end}>
                    <div className={`${classes.top_items} ${classes.exam_name}`}>
                        {localStorage.getItem("name")} {localStorage.getItem("lastName")}
                    </div>
                    <Popover content={content} title={`${sectionName}: ${moduleName}`} trigger="hover">
                        <Button
                            className={`${classes.top_items}`}
                            style={{ maxWidth: "200px" }}
                        >{`Question ${current} of ${numberOfQuestion}`}</Button>
                    </Popover>
                    <div className={`${classes.exam_buttons} ${classes.top_items}`}>
                        {current != 1 && (
                            <Button type="link" onClick={handleBack}>
                                Preview
                            </Button>
                        )}

                        {current == numberOfQuestion ? (
                            <Button onClick={() => setFinishExamModal(true)}>Next</Button>
                        ) : (
                            <Button type="primary" onClick={handleNext}>
                                Next
                            </Button>
                        )}
                    </div>
                </div>

                <div className={classes.custom_modal} style={{ display: isCalculatorVisible ? "block" : "none" }}>
                    <div className={classes.calculator_header}>
                        <span onClick={() => setIsCalculatorVisible(false)}>x</span>
                    </div>
                    <CalculatorModal />
                </div>
                <div className={classes.custom_modal} style={{ display: isExpressionVisible ? "block" : "none" }}>
                    <div className={classes.calculator_header}>
                        <span onClick={() => setIsExpressionVisible(false)}>x</span>
                    </div>
                    <ExpressionsModal />
                </div>

                <div className={classes.finish_exam_modal} style={{ display: `${finishExamModal ? "flex" : "none"}` }}>
                    <FinishExam
                        navigate={navigate}
                        moduleId={moduleId}
                        isNextModuleActive={isNextModuleActive}
                        setIsNextModuleActive={setIsNextModuleActive}
                        finishExam={finishExam}
                        setFinishExamModal={setFinishExamModal}
                        quizId={quizId}
                        modules={modules}
                        setCurrent={setCurrent}
                        setEndTime={setEndTime}
                        handleGoNext={handleGoNext}
                        isTimeExpire={isTimeExpire}
                    />
                </div>
            </div>
        </Spin>
    );
}

export default Exam;
