import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Button, Checkbox } from "antd";
import { useSearchParams } from "react-router-dom";
import Progress from "components/Progress/Progress";
import { httpClient } from "config/config";
import QuestionForm from "./QuestionForm/QuestionForm";
import classes from "./addQuestion.module.css";
import SelectTextModal from "./SelectTextModal";
import parse from "html-react-parser";

export default function AddQuestion() {
    const [current, setCurrent] = useState(1);
    const [selectedField, setSelectedField] = useState();
    const [selectedQuestionData, setSelectedQuestionData] = useState();

    const [orders, setOrders] = useState([]);
    const [exist, setExist] = useState(false);

    const [selectedText, setSelectedText] = useState();
    const [isTextQuestion, setIsTextQuestion] = useState(false);

    const [isVisible, setIsVisible] = useState(false);

    let [searchParams, setSearchParams] = useSearchParams();
    const quizId = searchParams.get("quizId");
    const moduleId = searchParams.get("moduleId");
    const navigate = useNavigate();

    const [numberOfQuestion, setNumberOfQuestion] = useState(0);
    const [moduleName, setModulName] = useState();
    const [sectionName, setSectionName] = useState();

    useEffect(() => {
        httpClient("modules/" + moduleId).then((res) => {
            setNumberOfQuestion(res?.data?.numberOfQuestion);
            setSectionName(res?.data?.section?.name);
            setModulName(res?.data?.name);
        });
        if (
            moduleId == "" ||
            quizId == "" ||
            moduleId == undefined ||
            moduleId == null ||
            quizId == undefined ||
            quizId == null
        ) {
            navigate("/admin/select-exam");
        } else {
            httpClient(`questions/orderNumbers/${quizId}/${moduleId}`).then((res) =>
                setOrders(res.data)
            );
        }
    }, []);

    useEffect(() => {
        if (orders.includes(current)) {
            httpClient(`questions/questionWithAnswer/${quizId}/${moduleId}/${current}`).then(
                (res) => {
                    setSelectedQuestionData(res?.data);
                    setSelectedText(res?.data?.textQuestion);
                    setExist(true);
                    if (res?.data?.textQuestion != null) {
                        setIsTextQuestion(true);
                    } else {
                        setIsTextQuestion(false);
                    }
                }
            );
        } else {
            setSelectedQuestionData();
            setIsTextQuestion(false);
            setSelectedText();
            setExist(false);
        }
    }, [current, orders]);

    const handleQuestionForm = (data) => {
        let trueAnswer = data?.trueAnswer;
        let openAnswer = data?.openAnswer;
        let openAnswer2 = data?.openAnswer2;
        let isAnswerText = data?.isAnswerText;

        const questionData = {
            isOpen: data.isOpen,
            isAnswerText: isAnswerText,
            isText: data.type,
            moduleId: moduleId,
            orderNumber: current,
            questionText: data.type ? data.textQuestion : data.questionText,
            quizId: quizId,
            textQuestionId: isTextQuestion ? selectedText?.id : null,
        };
        if (selectedQuestionData) {
            httpClient("questions/" + selectedQuestionData?.id, "put", questionData).then(() => {
                if (data?.isOpen) {
                    let answerData = {
                        answerText: openAnswer,
                        isTrue: true,
                        questionId: selectedQuestionData?.id,
                        variantId: 6,
                    };
                    if (
                        selectedQuestionData?.answers.length > 1 &&
                        selectedQuestionData?.answers.length !== 2
                    ) {
                        let ansForDelete = [data.answer1, data.answer2, data.answer3, data.answer4];
                        Promise.all(
                            ansForDelete.map((item, index) => {
                                return httpClient("answers/" + item.id, "delete");
                            })
                        ).then(() => {
                            httpClient("answers", "post", answerData).then(() => {
                                if (openAnswer2) {
                                    let answerData2 = {
                                        answerText: openAnswer2,
                                        isTrue: true,
                                        questionId: selectedQuestionData?.id,
                                        variantId: 7,
                                    };
                                    httpClient("answers", "post", answerData2);
                                }
                            });
                        });
                    } else {
                        httpClient(
                            selectedQuestionData?.answers[0]?.id
                                ? "answers/" + selectedQuestionData?.answers[0]?.id
                                : "answers/" + data?.openAnswer,
                            "put",
                            answerData
                        ).then(() => {
                            if (openAnswer2 && selectedQuestionData?.answers[1]?.id) {
                                let answerData2 = {
                                    answerText: openAnswer2,
                                    isTrue: true,
                                    questionId: selectedQuestionData?.id,
                                    variantId: 7,
                                };
                                httpClient(
                                    selectedQuestionData?.answers[1]?.id
                                        ? "answers/" + selectedQuestionData?.answers[1]?.id
                                        : "answers/" + data?.openAnswer,
                                    "put",
                                    answerData2
                                );
                            } else if (openAnswer2) {
                                let answerData2 = {
                                    answerText: openAnswer2,
                                    isTrue: true,
                                    questionId: selectedQuestionData?.id,
                                    variantId: 7,
                                };
                                httpClient("answers", "post", answerData2);
                            }
                        });
                    }
                } else {
                    if (selectedQuestionData?.isOpen) {
                        let answers = [
                            { answer: data.answer1 },
                            { answer: data.answer2 },
                            { answer: data.answer3 },
                            { answer: data.answer4 },
                        ];
                        Promise.all(
                            selectedQuestionData?.answers?.map((item, index) => {
                                return httpClient("answers/" + item.id, "delete");
                            })
                        ).then(() => {
                            answers.map((item, index) => {
                                let answerData = {
                                    answerText: item.answer.answerText,
                                    isTrue: trueAnswer - 1 === index ? true : false,
                                    questionId: selectedQuestionData?.id,
                                    variantId: index + 1,
                                };
                                httpClient("answers", "post", answerData).then(() => {});
                                setIsTextQuestion();
                            });
                        });
                    } else {
                        let answers = [data.answer1, data.answer2, data.answer3, data.answer4];
                        answers.map((item, index) => {
                            let answerData = {
                                answerText: item.answerText,
                                isTrue: trueAnswer == item.id ? true : false,
                                questionId: selectedQuestionData?.id,
                                variantId: index + 1,
                            };
                            httpClient("answers/" + item.id, "put", answerData).then(() => {});
                        });
                    }
                }
            });
        } else {
            httpClient("questions", "post", questionData).then((res) => {
                if (data?.isOpen) {
                    let answerData = {
                        answerText: openAnswer,
                        isTrue: true,
                        questionId: res?.data?.id,
                        variantId: 6,
                    };
                    let oldOrders = orders;
                    oldOrders.push(current);
                    setOrders(oldOrders);
                    httpClient("answers", "post", answerData).then(() => {
                        if (openAnswer2) {
                            let answerData2 = {
                                answerText: openAnswer2,
                                isTrue: true,
                                questionId: res?.data?.id,
                                variantId: 7,
                            };
                            httpClient("answers", "post", answerData2).then(() => {
                                if (current != numberOfQuestion) {
                                    setSelectedField(current);
                                    setCurrent(current + 1);
                                }
                            });
                        } else {
                            if (current != numberOfQuestion) {
                                setSelectedField(current);
                                setCurrent(current + 1);
                            }
                        }
                    });
                    setIsTextQuestion();
                } else {
                    let answers = [
                        { answer: data.answer1 },
                        { answer: data.answer2 },
                        { answer: data.answer3 },
                        { answer: data.answer4 },
                    ];
                    let oldOrders = orders;
                    oldOrders.push(current);
                    setOrders(oldOrders);
                    answers.map((item, index) => {
                        let answerData = {
                            answerText: item.answer,
                            isTrue: trueAnswer - 1 === index ? true : false,
                            questionId: res?.data?.id,
                            variantId: index + 1,
                        };
                        httpClient("answers", "post", answerData).then(() => {
                            if (index == 3 && current != numberOfQuestion) {
                                // 27 staticdir hele ki
                                setSelectedField(current);
                                setCurrent(current + 1);
                            }
                        });
                        setIsTextQuestion();
                    });
                }
            });
        }
    };

    return (
        <div className={classes.add_question}>
            <div className={classes.left}>
                <h4 style={{ fontWeight: "400", marginBottom: "50px" }}>
                    {sectionName}: {moduleName}
                </h4>
                <div className={classes.selectText}>
                    <Button onClick={() => setIsVisible(true)}>Text Sualını seçin</Button>
                    <Checkbox
                        onChange={(e) => setIsTextQuestion(e.target.checked)}
                        checked={isTextQuestion}
                        defaultChecked={isTextQuestion}
                        disabled={!selectedText}
                    >
                        Text sualı
                    </Checkbox>
                </div>

                <div>
                    <h4>Seçilmiş text:</h4>
                </div>

                {selectedText?.isText ? (
                    <div
                        className="custom_quil_heading"
                        style={{ maxWidth: "100%", textAlign: "left" }}
                    >
                        {parse(selectedText?.textContent)}
                    </div>
                ) : (
                    <img style={{ maxWidth: "100%" }} src={selectedText?.textContent} />
                )}

                <SelectTextModal
                    isVisible={isVisible}
                    setIsVisible={setIsVisible}
                    setSelectedText={setSelectedText}
                    selectedText={selectedText}
                />
            </div>
            <div className={classes.right}>
                <QuestionForm
                    handleQuestionForm={handleQuestionForm}
                    current={current}
                    setCurrent={setCurrent}
                    selectedQuestionData={selectedQuestionData}
                    exist={exist}
                />
                <div className={classes.progress_area}>
                    <Progress
                        disabledField={selectedField}
                        current={current}
                        data={numberOfQuestion}
                        onChange={(e) => setCurrent(e)}
                        orders={orders}
                    />
                </div>
            </div>
        </div>
    );
}
