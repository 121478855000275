// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login_login__WVHAz{\r\n    width: 100%;\r\n    height: 100vh;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    background-color: rgb(250, 248, 248);\r\n}\r\n.login_loginForm__eU\\+8x{\r\n    width: 350px;\r\n    border: 1px solid rgba(0, 0, 0, 0.2);\r\n    padding: 20px 30px ;\r\n    background: white;\r\n    border-radius: 10px;\r\n}", "",{"version":3,"sources":["webpack://./src/pages/user/login/login.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,oCAAoC;AACxC;AACA;IACI,YAAY;IACZ,oCAAoC;IACpC,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".login{\r\n    width: 100%;\r\n    height: 100vh;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    background-color: rgb(250, 248, 248);\r\n}\r\n.loginForm{\r\n    width: 350px;\r\n    border: 1px solid rgba(0, 0, 0, 0.2);\r\n    padding: 20px 30px ;\r\n    background: white;\r\n    border-radius: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": "login_login__WVHAz",
	"loginForm": "login_loginForm__eU+8x"
};
export default ___CSS_LOADER_EXPORT___;
