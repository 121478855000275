// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".adminDashboard_dashboard__-mkCS{\r\n    width: 100%;\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    flex-direction: row;\r\n    flex-wrap: wrap;\r\n    gap: 40px;\r\n}\r\n.adminDashboard_dashboard__-mkCS > div {\r\n    width: calc(50% - 20px);\r\n}\r\n.adminDashboard_module_header__B7R56{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n}\r\n\r\n@media only screen and (max-width: 1400px){\r\n    .adminDashboard_dashboard__-mkCS > div {\r\n        width: 100%;\r\n    }\r\n}", "",{"version":3,"sources":["webpack://./src/pages/admin/AdminDashboard/adminDashboard.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,eAAe;IACf,SAAS;AACb;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".dashboard{\r\n    width: 100%;\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    flex-direction: row;\r\n    flex-wrap: wrap;\r\n    gap: 40px;\r\n}\r\n.dashboard > div {\r\n    width: calc(50% - 20px);\r\n}\r\n.module_header{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n}\r\n\r\n@media only screen and (max-width: 1400px){\r\n    .dashboard > div {\r\n        width: 100%;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboard": "adminDashboard_dashboard__-mkCS",
	"module_header": "adminDashboard_module_header__B7R56"
};
export default ___CSS_LOADER_EXPORT___;
