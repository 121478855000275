import React, { useEffect, useState } from "react";
import { Button, Form, Select } from "antd";
import { httpClient } from "config/config";
import { useNavigate } from "react-router";
import { store } from "redux/store";
import { addModuleId, addQuizId } from "redux/reducers/AddQuizSlice";
import classes from "./addQuestion.module.css";

export default function SelectBoxes() {
    const [sections, setSections] = useState([]);
    const [modules, setModules] = useState([]);
    const [quizzes, setQuizzes] = useState([]);
    const [selectedSection, setSelectedSection] = useState();

    useEffect(() => {
        const getSections = httpClient("sections", "get");
        const getQuiz = httpClient("quizs", "get");
        Promise.all([getSections, getQuiz]).then((res) => {
            setSections(res[0].data);
            setQuizzes(res[1].data);
        });
    }, []);

    const navigate = useNavigate();

    const onFinish = (value) => {
        // store.dispatch(addModuleId(value.modulId));
        // store.dispatch(addQuizId(value.quizId));
        navigate(
            `/admin/select-exam/add-question?quizId=${value.quizId}&moduleId=${value.moduleId}`
        );
    };

    return (
        <div className={classes.select_box}>
            <Form layout="vertical" onFinish={onFinish} className={classes.select_box_form}>
                <Form.Item
                    label="Section seç"
                    name="sectionId"
                    rules={[
                        {
                            required: true,
                            message: "Section seçin",
                        },
                    ]}
                >
                    <Select
                        onChange={(e) =>
                            httpClient("sections/" + e).then((res) => {
                                setModules(res?.data?.modules);
                                setSelectedSection(e);
                            })
                        }
                    >
                        {sections.map((item) => (
                            <Select.Option value={item.id} key={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Modul seç"
                    name="moduleId"
                    rules={[
                        {
                            required: true,
                            message: "Modul seçin",
                        },
                    ]}
                >
                    <Select disabled={!selectedSection}>
                        {modules.map((item) => (
                            <Select.Option value={item.id} key={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Quiz seç"
                    name="quizId"
                    rules={[
                        {
                            required: true,
                            message: "Quiz seçin",
                        },
                    ]}
                >
                    <Select>
                        {quizzes.map((item) => (
                            <Select.Option value={item.id} key={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Button htmlType="submit">Seç və sual əlavə et</Button>
            </Form>
        </div>
    );
}
