import { Route, Routes } from "react-router";
import AdminDashboard from "./pages/admin/AdminDashboard/AdminDashboard";
import Navbar from "./pages/admin/AdminNavbar/Navbar";
import AddQuestion from "pages/admin/AddQuestion/AddQuestion";
import Exam from "pages/user/Exam";
import AddText from "pages/admin/AddText/AddText";
import Login from "pages/user/login/Login";
import AdminLogin from "pages/admin/AdminLogin/AdminLogin";
import SelectExam from "pages/user/SelectExam";
import SelectBoxes from "pages/admin/AddQuestion/SelectBoxes";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import ProtectedRoutes from "ProtectedRoutes";
import "./App.css";
import CheckAnswers from "pages/admin/CheckAnswers/CheckAnswers";
import Promocodes from "pages/admin/Promocodes/Promocodes";
import { useEffect } from "react";
import Report from "pages/admin/CheckAnswers/Report";


function App() {
    const isLoading = useSelector((state) => state.loaderReducer.isLoading);

    // useEffect(() => {
    //     alert("xeta bas verdii")
    //     window.addEventListener('beforeunload', alertUser)
    //     return () => {
    //       window.removeEventListener('beforeunload', alertUser)
    //     }
    //   }, [])

    //   const alertUser = e => {
    //     e.preventDefault()
    //     e.returnValue = 'asdhvsajdasdas'
    //   }

    return (
        <Spin spinning={isLoading} style={{ zIndex: "1", minHeight: "100vh" }}>
            <div className="App">
                <Routes>
                    <Route element={<ProtectedRoutes />}>
                        <Route path="/admin" element={<Navbar />}>
                            <Route
                                path="/admin/select-exam/add-question"
                                element={<AddQuestion />}
                            />
                            <Route path="/admin/select-exam" element={<SelectBoxes />} />
                            <Route path="/admin/add-text" element={<AddText />} />
                            <Route path="/admin/dashboard" element={<AdminDashboard />} />
                            <Route path="/admin/check-answers" element={<CheckAnswers />} />
                            <Route path="/admin/report" element={<Report />} />
                            <Route path="/admin/promocodes" element={<Promocodes />} />
                        </Route>
                    </Route>

                    <Route path="/exam" element={<Exam />}></Route>
                    <Route path="/login" element={<Login />}></Route>
                    <Route path="/select-exam" element={<SelectExam />}></Route>
                    <Route path="/admin-login" element={<AdminLogin />}></Route>
                    <Route path="*" element={<Login />}></Route>
                </Routes>
            </div>
        </Spin>
    );
}

export default App;
