import { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, InputNumber, Radio, Row, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import classes from "./questionForm.module.css";
import { useSearchParams } from "react-router-dom";
import ReactQuill from "react-quill";

export default function QuestionForm({ current, handleQuestionForm, selectedQuestionData, exist }) {
    const [type, setType] = useState(true);
    const [isAnswerText, setIsAnswerText] = useState(true);

    const [question, setQuestion] = useState();
    const [textQuestion, setTextQuestion] = useState();

    const [answer1, setAnswer1] = useState();
    const [answer2, setAnswer2] = useState();
    const [answer3, setAnswer3] = useState();
    const [answer4, setAnswer4] = useState();

    const {TextArea} = Input

    const [isOpen, setIsOpen] = useState(false);
    const [openAnswer, setOpenAnswer] = useState();
    const [openAnswer2, setOpenAnswer2] = useState();

    let [searchParams, setSearchParams] = useSearchParams();
    const quizId = searchParams.get("quizId");
    const moduleId = searchParams.get("moduleId");

    const [trueAnswer, setTrueAnswer] = useState();
    const [form] = Form.useForm();

    useEffect(() => {
        setAnswer1();
        setAnswer2();
        setAnswer3();
        setAnswer4();
        setQuestion();
        setTrueAnswer();
        setIsOpen(false);
        setOpenAnswer();
        setOpenAnswer2();
        setTextQuestion();
        form.setFieldValue("open", "");
        form.setFieldValue("open2", "");
    }, [current]);

    useEffect(() => {
        setOpenAnswer();
        setOpenAnswer2();
        if (selectedQuestionData) {
            setType(selectedQuestionData.isText ? true : false);
            setIsAnswerText(selectedQuestionData.isAnswerText ? true : false);
            setTrueAnswer();
            setAnswer1(selectedQuestionData.answers[0]);
            setAnswer2(selectedQuestionData.answers[1]);
            setAnswer3(selectedQuestionData.answers[2]);
            setAnswer4(selectedQuestionData.answers[3]);
            setIsOpen(selectedQuestionData.isOpen);
            if (selectedQuestionData.isOpen) {
                setOpenAnswer(selectedQuestionData.answers[0]?.answerText);
                setOpenAnswer2(selectedQuestionData.answers[1]?.answerText);
                form.setFieldValue("open", selectedQuestionData.answers[0]?.answerText);
                form.setFieldValue("open2", selectedQuestionData.answers[1]?.answerText);
            }
            selectedQuestionData?.answers?.map((item) => {
                if (item.isTrue) {
                    setTrueAnswer(item.id);
                }
            });
            if (selectedQuestionData.isText) {
                setTextQuestion(selectedQuestionData?.questionText);
            } else {
                setQuestion(selectedQuestionData?.questionText);
            }
        }
    }, [selectedQuestionData]);

    function encodeImageFileAsURL(element, type) {
        var file = element.file;
        var reader = new FileReader();
        reader.onloadend = function () {
            switch (type) {
                case "question": {
                    setQuestion(reader.result);
                    break;
                }
                case "answer1": {
                    if (selectedQuestionData) {
                        setAnswer1({ id: answer1?.id, answerText: reader.result });
                    } else {
                        setAnswer1(reader.result);
                    }
                    break;
                }
                case "answer2": {
                    if (selectedQuestionData) {
                        setAnswer2({ id: answer2?.id, answerText: reader.result });
                    } else {
                        setAnswer2(reader.result);
                    }
                    break;
                }
                case "answer3": {
                    if (selectedQuestionData) {
                        setAnswer3({ id: answer3?.id, answerText: reader.result });
                    } else {
                        setAnswer3(reader.result);
                    }
                    break;
                }
                case "answer4": {
                    if (selectedQuestionData) {
                        setAnswer4({ id: answer4?.id, answerText: reader.result });
                    } else {
                        setAnswer4(reader.result);
                    }
                    break;
                }
            }
        };
        reader.readAsDataURL(file);
    }
    return (
        <div>
            <Form layout="vertical" initialValues={{ open: openAnswer }} form={form}>
                <Form.Item label={`Sualı daxil edin - ${current}`}>
                    <Row>
                        <Radio.Group
                            defaultValue={type}
                            value={type}
                            onChange={(e) => {
                                setType(e.target.value);
                                // setText();
                            }}
                        >
                            <Radio value={false}>Şəkil</Radio>
                            <Radio value={true}>Mətn</Radio>
                        </Radio.Group>
                    </Row>
                    {type ? (
                        <ReactQuill
                            className="custom_quil_heading"
                            matchVisual={false}
                            theme="snow"
                            value={textQuestion}
                            onChange={(e) => {
                                setTextQuestion(e);
                            }}
                        />
                    ) : (
                        <Upload
                            beforeUpload={() => false}
                            listType="text"
                            onChange={(e) => encodeImageFileAsURL(e, "question")}
                            showUploadList={false}
                        >
                            {question ? (
                                <img
                                    src={question}
                                    alt="avatar"
                                    style={{ maxWidth: "100%", maxHeight: "500px" }}
                                />
                            ) : (
                                <Button
                                    style={{ height: "170px", width: "250px" }}
                                    icon={<UploadOutlined />}
                                >{`Sual ${current} yükləyin`}</Button>
                            )}
                        </Upload>
                    )}
                </Form.Item>

                <Form.Item
                    label="Cavabları daxil edin və düzgün cavabı seçin"
                    style={{ display: "flex", justifyContent: "flex-start" }}
                >
                    <Form.Item>
                        <Checkbox
                            checked={isOpen}
                            onChange={(e) => {
                                setIsOpen(e.target.checked);
                                setAnswer1();
                                setAnswer2();
                                setTrueAnswer();
                            }}
                        >
                            Açıq tipli
                        </Checkbox>
                    </Form.Item>
                    {!isOpen && (
                        <Row>
                            <Radio.Group
                                defaultValue={isAnswerText}
                                value={isAnswerText}
                                onChange={(e) => {
                                    setIsAnswerText(e.target.value);
                                    if (selectedQuestionData) {
                                        if (
                                            !(
                                                (e.target.value &&
                                                    selectedQuestionData.isAnswerText) ||
                                                (!e.target.value &&
                                                    !selectedQuestionData.isAnswerText)
                                            )
                                        ) {
                                            setAnswer1({ ...answer1, answerText: "" });
                                            setAnswer2({ ...answer2, answerText: "" });
                                            setAnswer3({ ...answer3, answerText: "" });
                                            setAnswer4({ ...answer4, answerText: "" });
                                        } else {
                                            setAnswer1(selectedQuestionData.answers[0]);
                                            setAnswer2(selectedQuestionData.answers[1]);
                                            setAnswer3(selectedQuestionData.answers[2]);
                                            setAnswer4(selectedQuestionData.answers[3]);
                                        }
                                    }
                                }}
                            >
                                <Radio value={false}>Şəkil</Radio>
                                <Radio value={true}>Mətn</Radio>
                            </Radio.Group>
                        </Row>
                    )}

                    {isOpen ? (
                        <>
                            <Form.Item name="open">
                                <Input
                                    value={openAnswer}
                                    defaultValue={openAnswer}
                                    onChange={(e) => setOpenAnswer(e.target.value)}
                                    // type="number"
                                    placeholder="Cavab"
                                />
                            </Form.Item>
                            <Form.Item name="open2">
                                <Input
                                    value={openAnswer2}
                                    defaultValue={openAnswer2}
                                    onChange={(e) => setOpenAnswer2(e.target.value)}
                                    // type="number"
                                    placeholder="Cavab 2"
                                />
                            </Form.Item>
                        </>
                    ) : (
                        <>
                            {isAnswerText ? (
                                <>
                                    <Radio.Group
                                        onChange={(e) => setTrueAnswer(e.target.value)}
                                        value={trueAnswer}
                                        defaultValue={trueAnswer}
                                    >
                                        <div className={classes.answerText_Radio}>
                                            <Radio
                                                value={
                                                    selectedQuestionData && answer1?.id
                                                        ? answer1?.id
                                                        : 1
                                                }
                                                className="custom_radio_text"
                                            >
                                                <span className="variant">A</span>
                                                <TextArea
                                                    style={{ width: "400px" }}
                                                    placeholder="Cavab 1"
                                                    value={
                                                        selectedQuestionData
                                                            ? answer1?.answerText
                                                            : answer1
                                                    }
                                                    onChange={(e) => {
                                                        console.log(e.target.value)
                                                        if (selectedQuestionData) {
                                                            setAnswer1({
                                                                id: answer1?.id,
                                                                answerText: e.target.value,
                                                            });
                                                        } else {
                                                            setAnswer1(e.target.value);
                                                        }
                                                    }}
                                                />
                                            </Radio>
                                            <Radio
                                                value={
                                                    selectedQuestionData && answer2?.id
                                                        ? answer2?.id
                                                        : 2
                                                }
                                                className="custom_radio_text"
                                            >
                                                <span className="variant">B</span>
                                                <TextArea
                                                    style={{ width: "400px" }}
                                                    placeholder="Cavab 2"
                                                    value={
                                                        selectedQuestionData
                                                            ? answer2?.answerText
                                                            : answer2
                                                    }
                                                    onChange={(e) => {
                                                        if (selectedQuestionData) {
                                                            setAnswer2({
                                                                id: answer2?.id,
                                                                answerText: e.target.value,
                                                            });
                                                        } else {
                                                            setAnswer2(e.target.value);
                                                        }
                                                    }}
                                                />
                                            </Radio>
                                            <Radio
                                                value={
                                                    selectedQuestionData && answer3?.id
                                                        ? answer3?.id
                                                        : 3
                                                }
                                                className="custom_radio_text"
                                            >
                                                <span className="variant">C</span>
                                                <TextArea
                                                    style={{ width: "400px" }}
                                                    placeholder="Cavab 3"
                                                    value={
                                                        selectedQuestionData
                                                            ? answer3?.answerText
                                                            : answer3
                                                    }
                                                    onChange={(e) => {
                                                        if (selectedQuestionData) {
                                                            setAnswer3({
                                                                id: answer3?.id,
                                                                answerText: e.target.value,
                                                            });
                                                        } else {
                                                            setAnswer3(e.target.value);
                                                        }
                                                    }}
                                                />
                                            </Radio>
                                            <Radio
                                                value={
                                                    selectedQuestionData && answer4?.id
                                                        ? answer4?.id
                                                        : 4
                                                }
                                                className="custom_radio_text"
                                            >
                                                <span className="variant">D</span>
                                                <TextArea
                                                    style={{ width: "400px" }}
                                                    placeholder="Cavab 4"
                                                    value={
                                                        selectedQuestionData
                                                            ? answer4?.answerText
                                                            : answer4
                                                    }
                                                    onChange={(e) => {
                                                        if (selectedQuestionData) {
                                                            setAnswer4({
                                                                id: answer4?.id,
                                                                answerText: e.target.value,
                                                            });
                                                        } else {
                                                            setAnswer4(e.target.value);
                                                        }
                                                    }}
                                                />
                                            </Radio>
                                        </div>
                                    </Radio.Group>
                                </>
                            ) : (
                                <Radio.Group
                                    onChange={(e) => setTrueAnswer(e.target.value)}
                                    value={trueAnswer}
                                    defaultValue={trueAnswer}
                                >
                                    <div className={classes.upload_item}>
                                        <Radio
                                            value={
                                                selectedQuestionData && answer1?.id
                                                    ? answer1?.id
                                                    : 1
                                            }
                                            className="custom_radio"
                                        >
                                            <span className="variant">A</span>
                                        </Radio>
                                        <Upload
                                            beforeUpload={() => false}
                                            listType="text"
                                            onChange={(e) => encodeImageFileAsURL(e, "answer1")}
                                            showUploadList={false}
                                        >
                                            {(!selectedQuestionData && answer1) ||
                                            answer1?.answerText ? (
                                                <img
                                                    src={
                                                        selectedQuestionData && answer1?.answerText
                                                            ? answer1?.answerText
                                                            : answer1
                                                    }
                                                    alt="avatar"
                                                    style={{ width: "100%", maxHeight: "170px" }}
                                                />
                                            ) : (
                                                <Button
                                                    style={{ height: "170px", width: "500px" }}
                                                    icon={<UploadOutlined />}
                                                >
                                                    Cavab 1-i əlavə edin
                                                </Button>
                                            )}
                                        </Upload>
                                    </div>
                                    <div className={classes.upload_item}>
                                        <Radio
                                            value={
                                                selectedQuestionData && answer2?.id
                                                    ? answer2?.id
                                                    : 2
                                            }
                                            className="custom_radio"
                                        >
                                            <span className="variant">B</span>
                                        </Radio>
                                        <Upload
                                            beforeUpload={() => false}
                                            listType="text"
                                            onChange={(e) => encodeImageFileAsURL(e, "answer2")}
                                            showUploadList={false}
                                        >
                                            {(!selectedQuestionData && answer2) ||
                                            answer2?.answerText ? (
                                                <img
                                                    src={
                                                        selectedQuestionData && answer2?.answerText
                                                            ? answer2?.answerText
                                                            : answer2
                                                    }
                                                    alt="avatar"
                                                    style={{ width: "100%", maxHeight: "170px" }}
                                                />
                                            ) : (
                                                <Button
                                                    style={{ height: "170px", width: "500px" }}
                                                    icon={<UploadOutlined />}
                                                >
                                                    Cavab 2-ni əlavə edin
                                                </Button>
                                            )}
                                        </Upload>
                                    </div>
                                    <div className={classes.upload_item}>
                                        <Radio
                                            value={
                                                selectedQuestionData && answer3?.id
                                                    ? answer3?.id
                                                    : 3
                                            }
                                            className="custom_radio"
                                        >
                                            <span className="variant">C</span>
                                        </Radio>
                                        <Upload
                                            beforeUpload={() => false}
                                            listType="text"
                                            onChange={(e) => encodeImageFileAsURL(e, "answer3")}
                                            showUploadList={false}
                                        >
                                            {(!selectedQuestionData && answer3) ||
                                            answer3?.answerText ? (
                                                <img
                                                    src={
                                                        selectedQuestionData && answer3?.answerText
                                                            ? answer3?.answerText
                                                            : answer3
                                                    }
                                                    alt="avatar"
                                                    style={{ width: "100%", maxHeight: "170px" }}
                                                />
                                            ) : (
                                                <Button
                                                    style={{ height: "170px", width: "500px" }}
                                                    icon={<UploadOutlined />}
                                                >
                                                    Cavab 3-ü əlavə edin
                                                </Button>
                                            )}
                                        </Upload>
                                    </div>
                                    <div className={classes.upload_item}>
                                        <Radio
                                            value={
                                                selectedQuestionData && answer4?.id
                                                    ? answer4?.id
                                                    : 4
                                            }
                                            className="custom_radio"
                                        >
                                            <span className="variant">D</span>
                                        </Radio>
                                        <Upload
                                            beforeUpload={() => false}
                                            listType="text"
                                            onChange={(e) => encodeImageFileAsURL(e, "answer4")}
                                            showUploadList={false}
                                        >
                                            {(!selectedQuestionData && answer4) ||
                                            answer4?.answerText ? (
                                                <img
                                                    src={
                                                        selectedQuestionData && answer4?.answerText
                                                            ? answer4?.answerText
                                                            : answer4
                                                    }
                                                    alt="avatar"
                                                    style={{ width: "100%", maxHeight: "170px" }}
                                                />
                                            ) : (
                                                <Button
                                                    style={{ height: "170px", width: "500px" }}
                                                    icon={<UploadOutlined />}
                                                >
                                                    Cavab 4-ü əlavə edin
                                                </Button>
                                            )}
                                        </Upload>
                                    </div>
                                </Radio.Group>
                            )}
                        </>
                    )}
                </Form.Item>
                <Button
                    type="primary"
                    disabled={
                        !(
                            moduleId != undefined &&
                            moduleId != null &&
                            quizId != undefined &&
                            quizId != null &&
                            answer1 &&
                            answer2 &&
                            answer3 &&
                            answer4 &&
                            (question || textQuestion) &&
                            trueAnswer
                        ) && !isOpen
                    }
                    onClick={() =>
                        handleQuestionForm({
                            questionText: question,
                            answer1,
                            answer2,
                            answer3,
                            answer4,
                            trueAnswer,
                            isOpen,
                            openAnswer,
                            openAnswer2,
                            type,
                            textQuestion,
                            isAnswerText,
                        })
                    }
                >
                    {exist ? "Yenilə" : "Daxil et və növbəti suala keç"}
                </Button>
            </Form>
        </div>
    );
}
