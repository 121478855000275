import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "antd";
import { httpClient } from "config/config";
import parse from 'html-react-parser'

export default function SelectTextModal({ setSelectedText, setIsVisible, isVisible }) {
    const [texts, setTexts] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [totalSize, setTotalSize] = useState();

    useEffect(() => {
        getTexts();
    }, []);

    const getTexts = (pageSize1, pageNo) =>
        httpClient(
            `textQuestions?pageNo=${pageNo ? pageNo - 1 : pageIndex}&pageSize=${
                pageSize1 ? pageSize1 : pageSize
            }&sortBy=id`
        ).then((res) => {
            setTexts(res.data.content);
            setTotalSize(res.data?.totalElements);
        });

    const changePage = (pg) => {
        setPageSize(pg.pageSize);
        setPageIndex(pg.current);
        getTexts(pg.pageSize, pg.current);
    };
    const columns = [
        {
            title: "ID",
            key: "id",
            dataIndex: "id",
        },
        {
            title: "Text",
            key: "textContent",
            dataIndex: "textContent",
            render: (value) => {
                return (
                    <>
                        {value.isText ? (
                            <div className="custom_quil_heading">{parse(value.textContent)}</div>
                        ) : (
                            <img src={value.textContent}  style={{maxWidth: "100%"}}/>
                        )}
                    </>
                );
            },
        },
    ];

    const tableData = texts.map((item, index) => {
        return {
            key: item.id,
            id: item.id,
            textContent: item,
        };
    });

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedText(selectedRows[0].textContent);
        },
    };

    return (
        <Modal
            open={isVisible}
            title="Text sualını seçin:"
            footer={null}
            width="800px"
            destroyOnClose={true}
            onCancel={() => setIsVisible(false)}
        >
            <Table
                rowSelection={{
                    type: "radio",
                    ...rowSelection,
                }}
                pagination={{
                    defaultPageSize: pageSize,
                    showSizeChanger: true,
                    pageSizeOptions: ["5", "10", "20"],
                    total: totalSize,
                }}
                onChange={changePage}
                columns={columns}
                dataSource={tableData}
            />
        </Modal>
    );
}
