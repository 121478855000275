import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addQuiz:null,
    addModule:null
};

const AddQuizSlice = createSlice({
    name: "quizModule",
    initialState,
    reducers: {
        addQuizId: (state,action) => {
            state.addQuiz = action.payload.addQuiz;
        },
        addModuleId: (state,action) => {
            state.addModule = action.payload.addModule;
        },
    },
});

export const { addQuizId, addModuleId } = AddQuizSlice.actions;
export default AddQuizSlice.reducer;