// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".addText_uploadButton__Lrojd{\r\n    border: 1px solid;\r\n    padding: 40px 100px;\r\n    border-radius: 10px;\r\n    font-size: 20px;\r\n    cursor: pointer;\r\n    width: 400px;\r\n    height: 200px;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n.addText_uploadArea__5UKuJ{\r\n    border: 1px solid;\r\n    border-radius: 10px;\r\n    font-size: 20px;\r\n    cursor: pointer;\r\n    width: 400px;\r\n    font-size: 16px;\r\n}\r\n\r\n", "",{"version":3,"sources":["webpack://./src/pages/admin/AddText/addText.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;IACf,eAAe;IACf,YAAY;IACZ,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,eAAe;IACf,eAAe;IACf,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".uploadButton{\r\n    border: 1px solid;\r\n    padding: 40px 100px;\r\n    border-radius: 10px;\r\n    font-size: 20px;\r\n    cursor: pointer;\r\n    width: 400px;\r\n    height: 200px;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n.uploadArea{\r\n    border: 1px solid;\r\n    border-radius: 10px;\r\n    font-size: 20px;\r\n    cursor: pointer;\r\n    width: 400px;\r\n    font-size: 16px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadButton": "addText_uploadButton__Lrojd",
	"uploadArea": "addText_uploadArea__5UKuJ"
};
export default ___CSS_LOADER_EXPORT___;
