import { useEffect, useState } from "react";
import { Button, Popconfirm, Table } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { httpClient } from "config/config";
import SectionsModal from "./SectionsModal";

export default function Sections() {
    const [isVisible, setIsVisible] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editData, setEditData] = useState();
    const [sections, setSections] = useState([]);

    const getSections = () => {
        httpClient("sections", "get").then((res) => setSections(res.data));
    };

    useEffect(() => {
        getSections();
    }, []);

    const deleteHandler = (id) => {
        httpClient("sections/" + id, "delete").then(() => getSections());
    };

    const column = [
        {
            title: "Adı",
            dataIndex: "name"
        },
    ];

    const tableData = sections.map((item) => {
        return {
            name: item.name,
            key: item.id,
        };
    });

    return (
        <div>
            <div className="module_header">
                <h3>Sectionlar</h3>
                <Button onClick={() => setIsVisible(true)}>Əlavə et</Button>
            </div>
            <Table
                pagination={false}
                scroll={{ y: "300px" }}
                columns={column}
                dataSource={tableData}
            />
            <SectionsModal
                editData={editData}
                editMode={editMode}
                getSections={getSections}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                setEditMode={setEditMode}
            />
        </div>
    );
}
