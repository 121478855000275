// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".addQuestion_add_question__9jfWj{\r\n    display: flex;\r\n    gap: 50px;\r\n    margin-top: 40px;\r\n}\r\n.addQuestion_left__07Efo {\r\n    width: 35%;\r\n    padding-right: 50px;\r\n    border-right: 1px solid rgba(0, 0, 0, 0.19);\r\n}\r\n.addQuestion_right__pFX5M {\r\n    width: 65%;\r\n}\r\n.addQuestion_progress_area__6sHCD{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    width: 100%;\r\n    margin-top: 40px;\r\n}\r\n.addQuestion_selectText__DQBSb{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-evenly;\r\n    margin-top: 30px;\r\n}\r\n.addQuestion_select_box__tLWcb{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n.addQuestion_select_box_form__9A5be{\r\n    width: 400px;\r\n}", "",{"version":3,"sources":["webpack://./src/pages/admin/AddQuestion/addQuestion.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,gBAAgB;AACpB;AACA;IACI,UAAU;IACV,mBAAmB;IACnB,2CAA2C;AAC/C;AACA;IACI,UAAU;AACd;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,YAAY;AAChB","sourcesContent":[".add_question{\r\n    display: flex;\r\n    gap: 50px;\r\n    margin-top: 40px;\r\n}\r\n.left {\r\n    width: 35%;\r\n    padding-right: 50px;\r\n    border-right: 1px solid rgba(0, 0, 0, 0.19);\r\n}\r\n.right {\r\n    width: 65%;\r\n}\r\n.progress_area{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    width: 100%;\r\n    margin-top: 40px;\r\n}\r\n.selectText{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-evenly;\r\n    margin-top: 30px;\r\n}\r\n.select_box{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n.select_box_form{\r\n    width: 400px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"add_question": "addQuestion_add_question__9jfWj",
	"left": "addQuestion_left__07Efo",
	"right": "addQuestion_right__pFX5M",
	"progress_area": "addQuestion_progress_area__6sHCD",
	"selectText": "addQuestion_selectText__DQBSb",
	"select_box": "addQuestion_select_box__tLWcb",
	"select_box_form": "addQuestion_select_box_form__9A5be"
};
export default ___CSS_LOADER_EXPORT___;
