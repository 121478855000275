import { message } from "antd";
import axios from "axios";
import { store } from "redux/store";
import { loading, ready } from "redux/reducers/LoaderSlice";
export const base_url = "https://nsacademy.az/back/";

export const httpClient = (url, method, data, headers = {}) => {
    const config = {
        method: method,
        url: base_url + url,
        data: data,
        headers: {
            ...{
                Accept: "application/json",
                Authorization: `Basic ${store.getState().adminAuthReducer.access_token}`,
                //Authorization: `Basic QWRtaW4yMzpBZG1pbjJAMjM=`, // ===== TEMPRORARY
            },
            ...headers,
        },
    };

    store.dispatch(loading());
    let check = false;
    axios.interceptors.response.use(
        (response) => {
            if (!check) {
                check = true;
                store.dispatch(ready());
            }
            return new Promise((resolve, reject) => {
                resolve(response);
            });
        },
        (error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
            }
            store.dispatch(ready());
            if (!check) {
                check = true;
                if (error?.code === "ERR_NETWORK") {
                    message.error("Sistem xətası");
                }
            }
            return new Promise((resolve, reject) => {
                reject(error);
            });
        }
    );
    return axios(config);
};
