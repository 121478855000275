import React, { useEffect, useState } from "react";
import classes from "./progress.module.css";

export default function Progress({
    data,
    onChange,
    current,
    orders,
    marked_questions,
    type,
    userAnswerList,
}) {
    const [questionNumbersList, setQuestionNumbersList] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(1);

    useEffect(() => {
        let initialData = [];
        let a = 0;
        while (a < data) {
            a++;
            initialData.push(a);
        }
        setQuestionNumbersList(initialData);
    }, [data]);

    useEffect(() => {
        setCurrentQuestion(current);
    }, [current]);

    const handleChange = (item) => {
        setCurrentQuestion(item);
        onChange(item);
    };
    return (
        <div className={classes.progress}>
            {type == "check_answers" ? (
                <>
                    {questionNumbersList.map((item) => (
                        <div
                            key={item}
                            onClick={() => {
                                handleChange(item);
                            }}
                            className={`${userAnswerList[item - 1]?.correctOpenAnswer?.includes(
                                userAnswerList[item - 1]?.userAnswer)
                                    ? classes.true_answer
                                    : classes.false_answer
                            } ${classes.progress_item}  ${
                                orders?.includes(item) && classes.progress_order
                            } ${
                                item == currentQuestion && classes.progress_item_check_answer_active
                            }  `}
                        >
                            {item}
                            {marked_questions?.includes(item) && (
                                <svg
                                    className={classes.progress_mark_icon}
                                    xmlns=""
                                    x="0px"
                                    y="0px"
                                    width="50"
                                    height="50"
                                    viewBox="0 0 50 50"
                                >
                                    <path d="M 37 48 C 36.824219 48 36.652344 47.953125 36.496094 47.863281 L 25 41.15625 L 13.503906 47.863281 C 13.195313 48.042969 12.8125 48.046875 12.503906 47.867188 C 12.191406 47.6875 12 47.359375 12 47 L 12 3 C 12 2.449219 12.449219 2 13 2 L 37 2 C 37.554688 2 38 2.449219 38 3 L 38 47 C 38 47.359375 37.808594 47.6875 37.496094 47.867188 C 37.34375 47.957031 37.171875 48 37 48 Z"></path>
                                </svg>
                            )}
                        </div>
                    ))}
                </>
            ) : (
                <>
                    {questionNumbersList.map((item) => (
                        <div
                            key={item}
                            onClick={() => {
                                handleChange(item);
                            }}
                            className={`${classes.progress_item}  ${
                                orders?.includes(item) && classes.progress_order
                            } ${item == currentQuestion && classes.progress_item_active}`}
                        >
                            {item}
                            {marked_questions?.includes(item) && (
                                <svg
                                    className={classes.progress_mark_icon}
                                    xmlns=""
                                    x="0px"
                                    y="0px"
                                    width="50"
                                    height="50"
                                    viewBox="0 0 50 50"
                                >
                                    <path d="M 37 48 C 36.824219 48 36.652344 47.953125 36.496094 47.863281 L 25 41.15625 L 13.503906 47.863281 C 13.195313 48.042969 12.8125 48.046875 12.503906 47.867188 C 12.191406 47.6875 12 47.359375 12 47 L 12 3 C 12 2.449219 12.449219 2 13 2 L 37 2 C 37.554688 2 38 2.449219 38 3 L 38 47 C 38 47.359375 37.808594 47.6875 37.496094 47.867188 C 37.34375 47.957031 37.171875 48 37 48 Z"></path>
                                </svg>
                            )}
                        </div>
                    ))}
                </>
            )}
        </div>
    );
}
