import { Button, Form, Input, message, Typography } from "antd";
import axios from "axios";
import { base_url } from "config/config";
import { useNavigate } from "react-router";
import { handleLogin } from "redux/reducers/adminAuthSlice";
import { store } from "redux/store";
import classes from "./adminLogin.module.css";
const { Title } = Typography;

const AdminLogin = () => {
    //pass = Admin2@23
    //name = Admin23

    const navigate = useNavigate();

    const onFinish = (values) => {
        const token = window.btoa(values.username + ":" + values.password);
        axios({
            method: "post",
            url: base_url + "auth",
            auth: { username: values.username, password: values.password },
        })
            .then(() => {
                store.dispatch(handleLogin({ access_token: token }));
                // localStorage.setItem("basic_token", token);
                navigate("/admin/dashboard");
            })
            .catch(() => message.error("İstifadəçi adı və ya şifrə yanlışdır"));
    };

    return (
        <div className={classes.login}>
            <Form name="normal_login" className={classes.loginForm} onFinish={onFinish}>
                <Typography>
                    <Title type="primary">Daxil ol</Title>
                </Typography>

                <Form.Item
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: "İstifadəçi adın daxil edin",
                        },
                    ]}
                >
                    <Input type="text" placeholder="İstifadəçi adı" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: "Şifrəni daxil edin",
                        },
                    ]}
                >
                    <Input type="password" placeholder="Şifrə" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Giriş
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};
export default AdminLogin;
