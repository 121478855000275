import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn: false,
    access_token: null,
};

const adminAuthService = createSlice({
    name: "admin_auth",
    initialState,
    reducers: {
        handleLogin: (state, action) => {
            state.access_token = action.payload.access_token;
            state.isLoggedIn = true;
        },
        handleLogout: (state) => {
            state.access_token = null;
            state.isLoggedIn = false;
        },
    },
});

export const { handleLogin, handleLogout } = adminAuthService.actions;
export default adminAuthService.reducer;
