import { Link, Outlet } from "react-router-dom";
import {
    HomeOutlined,
    FileAddOutlined,
    FileTextOutlined,
    OrderedListOutlined,
    CheckSquareOutlined,
    QrcodeOutlined,
    BarcodeOutlined,
} from "@ant-design/icons";
import classes from "./navbar.module.css";
import { store } from "redux/store";
import { handleLogout } from "redux/reducers/adminAuthSlice";

export default function Navbar() {
    const handleAdminLogout = ()=>{
        localStorage.removeItem("basic_token")
        store.dispatch(handleLogout())
    }

    return (
        <>
            <div className={classes.page_container}>
                <div className={classes.main_left}>
                    <div className={classes.sidebar}>
                        <ul>
                            <Link to="/admin/dashboard">
                                <OrderedListOutlined />
                            </Link>
                            <Link to="/admin/select-exam">
                                <FileAddOutlined />
                            </Link>
                            <Link to="/admin/add-text">
                                <FileTextOutlined />
                            </Link>
                            <Link to="/admin/report">
                                <CheckSquareOutlined />
                            </Link>
                            <Link to="/admin/promocodes">
                                <QrcodeOutlined />
                            </Link>
                        </ul>
                    </div>
                </div>
                <div className={classes.main_right}>
                    <div className={classes.navbar}>
                        <h2>Admin Dashboard</h2>
                        <Link
                            to="/admin-login"
                            onClick={handleAdminLogout}
                            className={classes.login}
                        >
                            Çıxış
                        </Link>
                    </div>
                    <div className={classes.main_container}>
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
}
